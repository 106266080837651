import React from 'react';
// import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
// import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import { ReactComponent as IconRonin } from './assets/icons/ronin.svg';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo" />
          <div className="social">
            {/* <a href="https://facebook.com" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a> */}
            <a href="https://app.roninchain.com/validator/ronin:bd4bf317da1928cc2f9f4da9006401f3944a0ab5" title="Ronin Validator Node" target="_blank" rel="noopener noreferrer">
              <IconRonin className="icon" />
            </a>
            <a href="https://twitter.com/blockahead_dev" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" />
            </a>
            {/* <a href="https://github.com/arkn98/coming-soon" title="GitHub" target="_blank" rel="noopener noreferrer">
              <IconGithub className="icon" />
            </a> */}
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Secure Your Transactions and Stay <span className='ahead'>Ahead</span> of the Game.</h1>
            <p>Stay safe from frontrunning and sandwich attacks with Block Ahead's private Ronin RPC endpoint.</p>
          </div>
          {/* <a href="mailto:user@example.com">
            <div className="cta">Send us an email</div>
          </a> */}
        </div>
        <div className="footer">
          <span>© 2023 FutureBlock</span>
        </div>
      </div>
    );
  }
}

export default App;