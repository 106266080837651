import React from 'react';
import { createRoot } from "react-dom/client";

import './styles/reset.css';
import "./styles/fonts.css";
import "./index.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
import * as serviceWorker from './serviceWorker';


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <App />
);

reportWebVitals(sendToVercelAnalytics);
serviceWorker.unregister();
